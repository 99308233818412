/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 55px;
  padding: 15px 12px;
  width: 100%;
  color: #a9a9a9;
  background-color: white;
  border: 1px solid #C0C0C0;
  border-radius: 4px;

  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}



.StripeElement--focus {
    border-color: #13c1cf;
    border: 2px solid #13c1cf;
    outline:0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}